
import { useContext } from "react";
import PublicUrlContext from '../Common';

function Header() {
    
    const publicUrl = useContext(PublicUrlContext);

    return (
        <>
            <header className="cbp-row wh-header wh-header-inner ">
                <div className="cbp-row wh-top-bar-additional">
                    <div className="cbp-container">
                        <div className="one whole wh-padding align-left">
                            <div className="scp-shortcode scp-icon-bullet-text pull-right" style={{"padding":"0px"}}>
                                <div className="align-center scp-icon-bullet-text-icon" style={{"color":"#cfac7e","fontSize":"40px"}}><i className="icon-Map-Marker2"></i></div>
                                <div className="scp-icon-bullet-text-text pad-left">
                                    <h5 className="title" style={{"color":"#704033"}}>5475 Trafalgar Rd.</h5>
                                    <h6 className="subtitle" style={{"color":"#704033"}}>Erin, ON N0B 1T0</h6>
                                    <p className="description" style={{"color":"#000"}}></p>
                                </div>
                            </div>
                            <div className="scp-shortcode-separator hide-on-mobile hide-on-small-tablet" style={{"width":"1px","height":"45px","backgroundColor":"#000","margin":"0 20px","float":"right"}}></div>
                            <div className="scp-shortcode scp-icon-bullet-text pull-right" style={{"padding":"0px"}}>
                                <div className="align-center scp-icon-bullet-text-icon" style={{"color":"#cfac7e","fontSize":"40px"}}><i className="icon-Phone-2"></i></div>
                                <div className="scp-icon-bullet-text-text pad-left">
                                    <h5 className="title" style={{"color":"#704033"}}>+519 833 1033</h5>
                                    <h6 className="subtitle" style={{"color":"#704033"}}>foxrunrhome@gmail.com</h6>
                                    <p className="description" style={{"color":"#000"}}></p>
                                </div>
                            </div>
                            <div className="scp-shortcode-separator hide-on-mobile hide-on-small-tablet" style={{"width":"1px","height":"45px","backgroundColor":"#000","margin":"0 20px","float":"right"}}></div>
                            <div className="scp-shortcode scp-icon-bullet-text pull-right" style={{"padding":"0px"}}>
                                <div className="align-center scp-icon-bullet-text-icon" style={{"color":"#cfac7e","fontSize":"40px"}}><i className="icon-Clock"></i></div>
                                <div className="scp-icon-bullet-text-text pad-left">
                                    <h5 className="title" style={{"color":"#704033"}}>Mon - Fri 7am-7pm</h5>
                                    <h6 className="subtitle" style={{"color":"#704033"}}>Saturday-Sunday(9am-5pm)</h6>
                                    <p className="description" style={{"color":"#000"}}></p>
                                </div>
                            </div>
                            <div className="scp-shortcode-separator hide-on-mobile hide-on-small-tablet" style={{"width":"100%","height":"1px","backgroundColor":"#cccccc","margin":"20px 0 0 0","float":"left"}}></div>
                        </div>
                    </div>
                </div>
                <div className="wh-main-menu-bar-wrapper wh-sticky-header-enabled">
                    <div className="cbp-container">
                        <div className="one fourth wh-padding">
                            <div className="wh-logo align-left">
                                <a href={publicUrl+'/'}>
                                <img src={publicUrl+'/wp-content/uploads/2016/02/foxrun_tiny-logo.png'} alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="three fourths wh-padding">
                            <div id="cbp-menu-main">
                                <div className="menu-one-page-menu-container">
                                    <ul id="menu-one-page-menu" className="sf-menu wh-menu-main pull-right">
                                        <li id="menu-item-1915" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1915"><a href="#about-us">About Us</a></li>
                                        <li id="menu-item-1916" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1916"><a href="#our-features">Our Retirement Home</a></li>
                                        <li id="menu-item-1917" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1917"><a href="#blog-and-gallery">Gallery</a></li>
                                        <li id="menu-item-1918" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1918"><a href="#our-services">Our Services</a></li>
                                        <li id="menu-item-1920" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1920"><a href="#contact-us">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;