import { useContext } from "react";
import PublicUrlContext from '../Common';

function Banner() {

    const publicUrl = useContext(PublicUrlContext);

    return (
        <>
            <div  className="vc_row wpb_row vc_row-fluid vc_custom_1420887057971">
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_custom_1424084094704">
                    <div className="wpb_wrapper">
                        <div className="wpb_layerslider_element wpb_content_element">
                            <div className="ls-wp-fullwidth-container" style={{"height":"610px"}}>
                                <div className="ls-wp-fullwidth-helper">
                                    <div id="layerslider_2_1539502786_1" className="ls-wp-container" style={{"width":"100%","height":"610px","margin":"0 auto","marginBottom":"0px"}}>
                                        <div className="ls-slide" data-ls=" transition2d: all;">
                                            <img src={publicUrl+"/wp-content/uploads/layerslider/Homepage/slider.png"} className="ls-bg" alt="slider" />
                                            <p className="ls-l" style={{"top":"205px","left":"480px","textAlign":"center","width":"900px","fontFamily":"Playfair Display","fontSize":"50px","color":"#ffffff"}} data-ls="offsetxin:0;durationin:3000;delayin:200;offsetxout:0;"><span style={{"fontSize":"24px"}}>SPECIALIZED SENIOR RETIREMENT LIVING FOR <br /> ALZHEIMER'S DISEASE &amp; RELATED DEMENTIA.</span></p>
                                            <p className="ls-l hide-on-small-tablet" style={{"top":"351px","left":"716px","fontFamily":"Montserrat","fontSize":"13x","color":"#ffffff","whiteSpace":"nowrap"}} data-ls="offsetxin:0;durationin:3000;delayin:200;offsetxout:0;"><span style={{"float":"left","marginTop":"10px"}}><br /><br /><br />Discover the difference in private care for your loved one today.</span></p>
                                            <p className="ls-l" style={{"top":"204px","left":"600px","whiteSpace":"nowrap"}}></p>
                                            <p className="ls-l" style={{"top":"142px","left":"851px","whiteSpace":"nowrap"}}></p>
                                            <img className="ls-l hide-on-small-tablet" style={{"top":"514px","left":"-4px","whiteSpace":"nowrap"}} src={publicUrl+"/wp-content/uploads/layerslider/Homepage/bg-tekst-buttons.png"} alt="" />
                                            <h5 className="ls-l hide-on-small-tablet" style={{"top":"557px","left":"385px","color":"#ffffff","whiteSpace":"nowrap"}}>Or send us an email through our contact form, check below contact form</h5>
                                            <a href="#contact-us" target="_self" className="ls-l" style={{"top":"538px","left":"1316px"}}>
                                                <p className="hide-on-small-tablet" style={{"paddingTop":"14px","paddingRight":"20px","paddingBottom":"14px","paddingLeft":"20px","borderTop":"1px solid #fff","borderRight":"1px solid #fff","borderBottom":"1px solid #fff","borderLeft":"1px solid #fff","fontSize":"16px","color":"#ffffff","borderRadius":"1px","whiteSpace":"nowrap"}}><span>MAKE ENQUIRY</span></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;