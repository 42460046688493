import { useContext } from "react";
import PublicUrlContext from '../Common';

function AboutUs() {

    const publicUrl = useContext(PublicUrlContext);

    return (

        <>
            <div id="about-us" className="vc_row wpb_row vc_row-fluid">
                <div className="cbp-container">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="wpb_wrapper">
                            <div id="ultimate-heading5bc2f2c255d69" className="uvc-heading ultimate-heading5bc2f2c255d69 uvc-8579 " data-hspacer="line_with_icon" data-hline_width="200" data-hicon_type="custom" data-hborder_style="solid" data-hborder_height="1" data-hborder_color="rgba(193,193,193,0.63)" data-icon_width="60" data-hfixer="10"  data-halign="center" style={{"textAlign":"center"}}>
                                <div className="uvc-main-heading ult-responsive"  data-ultimate-target='.uvc-heading.ultimate-heading5bc2f2c255d69 h2'  data-responsive-json-new='{"font-size":"desktop:35px;","line-height":"desktop:45px;"}' >
                                    <h2 style={{"fontFamily":"'Playfair Display'","fontWeight":"normal","fontStyle":"normal","color":"#4c4c4c","marginTop":"70px"}}>About Us</h2>
                                </div>
                                <div className="uvc-heading-spacer line_with_icon" style={{"marginTop":"10px","marginBottom":"10px"}}>
                                    <div className="ult-just-icon-wrapper ">
                                        <div className="align-icon" style={{"textAlign":"center"}}>
                                            <div className="aio-icon-img " style={{"fontSize":"60px","display":"inline-block"}} >
                                                <img className="img-icon" alt="" src={publicUrl + "/wp-content/uploads/2014/12/separatstar-grey1.png"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uvc-sub-heading ult-responsive"  data-ultimate-target='.uvc-heading.ultimate-heading5bc2f2c255d69 .uvc-sub-heading '  data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:24px;"}'  style={{"fontFamily":"'Playfair Display'","fontWeight":"normal","fontStyle":"normal","marginTop":"0px","marginBottom":"30px"}}>Our mission is to provide an environment where residents are nurtured to contribute, thrive and grow. While preserving their dignity and enhancing their quality of life.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AboutUs;