import Header from './Sections/Header';
import Banner from './Sections/Banner';
import AboutUs from './Sections/AboutUs';
import PublicUrlContext from './Common';


function App() {
  return (
    <>
      <PublicUrlContext.Provider value={process.env.PUBLIC_URL}>
        <Header />
        <Banner />
        <AboutUs />
      </PublicUrlContext.Provider>
    </>
  );
}

export default App;
